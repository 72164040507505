module.exports = [{
      plugin: require('/Applications/MAMP/htdocs/mj-nutrition/node_modules/gatsby-plugin-typography/gatsby-browser.js'),
      options: {"plugins":[],"pathToConfigModule":"src/utils/typography"},
    },{
      plugin: require('/Applications/MAMP/htdocs/mj-nutrition/node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"MJNutrition","short_name":"MJNutrition","start_url":"/","background_color":"#073a7a","theme_color":"#073a7a","display":"standalone","icon":"src/images/icon.png"},
    },{
      plugin: require('/Applications/MAMP/htdocs/mj-nutrition/node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"UA-39342352-74"},
    },{
      plugin: require('/Applications/MAMP/htdocs/mj-nutrition/node_modules/gatsby-plugin-google-tagmanager/gatsby-browser.js'),
      options: {"plugins":[],"id":"GTM-KM27X5V","includeInDevelopment":false,"defaultDataLayer":{"type":"object","value":{"platform":"gatsby"}}},
    },{
      plugin: require('/Applications/MAMP/htdocs/mj-nutrition/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
