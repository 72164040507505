// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("/Applications/MAMP/htdocs/mj-nutrition/src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-us-js": () => import("/Applications/MAMP/htdocs/mj-nutrition/src/pages/about-us.js" /* webpackChunkName: "component---src-pages-about-us-js" */),
  "component---src-pages-cookies-policy-js": () => import("/Applications/MAMP/htdocs/mj-nutrition/src/pages/cookies-policy.js" /* webpackChunkName: "component---src-pages-cookies-policy-js" */),
  "component---src-pages-event-experience-js": () => import("/Applications/MAMP/htdocs/mj-nutrition/src/pages/event-experience.js" /* webpackChunkName: "component---src-pages-event-experience-js" */),
  "component---src-pages-event-experience-explore-babys-development-js": () => import("/Applications/MAMP/htdocs/mj-nutrition/src/pages/event-experience/explore-babys-development.js" /* webpackChunkName: "component---src-pages-event-experience-explore-babys-development-js" */),
  "component---src-pages-event-experience-family-js": () => import("/Applications/MAMP/htdocs/mj-nutrition/src/pages/event-experience/family.js" /* webpackChunkName: "component---src-pages-event-experience-family-js" */),
  "component---src-pages-event-experience-grazing-js": () => import("/Applications/MAMP/htdocs/mj-nutrition/src/pages/event-experience/grazing.js" /* webpackChunkName: "component---src-pages-event-experience-grazing-js" */),
  "component---src-pages-event-experience-learn-about-our-ingredients-js": () => import("/Applications/MAMP/htdocs/mj-nutrition/src/pages/event-experience/learn-about-our-ingredients.js" /* webpackChunkName: "component---src-pages-event-experience-learn-about-our-ingredients-js" */),
  "component---src-pages-event-experience-milk-js": () => import("/Applications/MAMP/htdocs/mj-nutrition/src/pages/event-experience/milk.js" /* webpackChunkName: "component---src-pages-event-experience-milk-js" */),
  "component---src-pages-home-js": () => import("/Applications/MAMP/htdocs/mj-nutrition/src/pages/home.js" /* webpackChunkName: "component---src-pages-home-js" */),
  "component---src-pages-index-js": () => import("/Applications/MAMP/htdocs/mj-nutrition/src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-our-nutrition-pledge-js": () => import("/Applications/MAMP/htdocs/mj-nutrition/src/pages/our-nutrition-pledge.js" /* webpackChunkName: "component---src-pages-our-nutrition-pledge-js" */),
  "component---src-pages-privacy-notice-js": () => import("/Applications/MAMP/htdocs/mj-nutrition/src/pages/privacy-notice.js" /* webpackChunkName: "component---src-pages-privacy-notice-js" */),
  "component---src-pages-terms-of-use-js": () => import("/Applications/MAMP/htdocs/mj-nutrition/src/pages/terms-of-use.js" /* webpackChunkName: "component---src-pages-terms-of-use-js" */),
  "component---src-pages-tips-for-flying-js": () => import("/Applications/MAMP/htdocs/mj-nutrition/src/pages/tips-for-flying.js" /* webpackChunkName: "component---src-pages-tips-for-flying-js" */),
  "component---src-pages-visit-us-js": () => import("/Applications/MAMP/htdocs/mj-nutrition/src/pages/visit-us.js" /* webpackChunkName: "component---src-pages-visit-us-js" */),
  "component---src-pages-zh-about-us-js": () => import("/Applications/MAMP/htdocs/mj-nutrition/src/pages/zh/about-us.js" /* webpackChunkName: "component---src-pages-zh-about-us-js" */),
  "component---src-pages-zh-cookies-policy-js": () => import("/Applications/MAMP/htdocs/mj-nutrition/src/pages/zh/cookies-policy.js" /* webpackChunkName: "component---src-pages-zh-cookies-policy-js" */),
  "component---src-pages-zh-event-experience-js": () => import("/Applications/MAMP/htdocs/mj-nutrition/src/pages/zh/event-experience.js" /* webpackChunkName: "component---src-pages-zh-event-experience-js" */),
  "component---src-pages-zh-event-experience-explore-babys-development-js": () => import("/Applications/MAMP/htdocs/mj-nutrition/src/pages/zh/event-experience/explore-babys-development.js" /* webpackChunkName: "component---src-pages-zh-event-experience-explore-babys-development-js" */),
  "component---src-pages-zh-event-experience-family-js": () => import("/Applications/MAMP/htdocs/mj-nutrition/src/pages/zh/event-experience/family.js" /* webpackChunkName: "component---src-pages-zh-event-experience-family-js" */),
  "component---src-pages-zh-event-experience-grazing-js": () => import("/Applications/MAMP/htdocs/mj-nutrition/src/pages/zh/event-experience/grazing.js" /* webpackChunkName: "component---src-pages-zh-event-experience-grazing-js" */),
  "component---src-pages-zh-event-experience-learn-about-our-ingredients-js": () => import("/Applications/MAMP/htdocs/mj-nutrition/src/pages/zh/event-experience/learn-about-our-ingredients.js" /* webpackChunkName: "component---src-pages-zh-event-experience-learn-about-our-ingredients-js" */),
  "component---src-pages-zh-event-experience-milk-js": () => import("/Applications/MAMP/htdocs/mj-nutrition/src/pages/zh/event-experience/milk.js" /* webpackChunkName: "component---src-pages-zh-event-experience-milk-js" */),
  "component---src-pages-zh-index-js": () => import("/Applications/MAMP/htdocs/mj-nutrition/src/pages/zh/index.js" /* webpackChunkName: "component---src-pages-zh-index-js" */),
  "component---src-pages-zh-our-nutrition-pledge-js": () => import("/Applications/MAMP/htdocs/mj-nutrition/src/pages/zh/our-nutrition-pledge.js" /* webpackChunkName: "component---src-pages-zh-our-nutrition-pledge-js" */),
  "component---src-pages-zh-privacy-notice-js": () => import("/Applications/MAMP/htdocs/mj-nutrition/src/pages/zh/privacy-notice.js" /* webpackChunkName: "component---src-pages-zh-privacy-notice-js" */),
  "component---src-pages-zh-terms-of-use-js": () => import("/Applications/MAMP/htdocs/mj-nutrition/src/pages/zh/terms-of-use.js" /* webpackChunkName: "component---src-pages-zh-terms-of-use-js" */),
  "component---src-pages-zh-tips-for-flying-js": () => import("/Applications/MAMP/htdocs/mj-nutrition/src/pages/zh/tips-for-flying.js" /* webpackChunkName: "component---src-pages-zh-tips-for-flying-js" */),
  "component---src-pages-zh-visit-us-js": () => import("/Applications/MAMP/htdocs/mj-nutrition/src/pages/zh/visit-us.js" /* webpackChunkName: "component---src-pages-zh-visit-us-js" */)
}

